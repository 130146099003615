<template>
    <div class="content">
        <div>
            <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
        </div>

        <el-card style="margin-top:15px;width:100%" class="box-card">
            <div class="search">
                <div class="item">
                    <label>账号</label>
                    <el-input v-model="searchForm.user_login_id" class="marg" style="width:150px"></el-input>
                </div>
                <div class="item">
                    <label>角色</label>
                    <el-select v-model="searchForm.gp_id" style="width:150px">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option v-for="item in $store.state.GroupDataList" :key="item.gp_id" :label="item.gp_name" :value="item.gp_id"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <label>状态</label>
                    <el-select v-model="searchForm.user_state" style="width:150px">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="禁用" :value="1"></el-option>
                        <el-option label="正常" :value="2"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <label>创建时间</label>
                    <el-date-picker v-model="searchForm.create_time" type="daterange" range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </div>

                <el-button type="primary" plain @click="Search" style="margin-left: 20px;">查询</el-button>
            </div>
        </el-card>

        <el-table :data='$store.state.UserDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true' size="mini">
            <el-table-column align="center" prop="user_login_id" label="账号"></el-table-column>
            <el-table-column align="center" prop="user_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="user_mail" label="邮箱"></el-table-column>
            <el-table-column align="center" prop="gp_name" label="角色"></el-table-column>
            <el-table-column align="center" prop="gp_name" label="账号所属">
                <template scope="slot">
                    {{slot.row.user_type===0?'管理员':(slot.row.user_type===1?'企业':(slot.row.user_type===2?'规自局':(slot.row.user_type===3?'鑫诚公司':(slot.row.user_type===4?'交警中队':(slot.row.user_type===5?'交警大队':(slot.row.user_type===6?'分管领导':(slot.row.user_type===7?'局长':'')))))))}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="user_state" label="状态">
                <template scope="slot">
                    <el-tag :type="slot.row.user_state===0?'danger':'success'">{{slot.row.user_state===0?'禁用':'正常'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                    <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog v-if="userDialog" :title="title" :visible.sync="userDialog" width='40%' :append-to-body='true'>
            <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px" size='medium'>
                <el-form-item label="用户名" prop="user_name">
                     <el-input v-model="userForm.user_name"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="user_login_id">
                    <el-input v-model="userForm.user_login_id" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="所属角色" prop="user_group_id">
                    <el-select v-model="userForm.user_group_id" style="width:100%;">
                        <el-option v-for='item in $store.state.GroupDataList' :key='item.gp_id' :label='item.gp_name' :value='item.gp_id'></el-option>
                        <el-option :value='0' label=' '></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="账号所属" prop="user_type">
                    <el-radio-group v-model="userForm.user_type" style="width:100%;">
                        <div>
                            <el-radio :label="0">管理员</el-radio>
                            <el-radio :label="1">企业</el-radio>
                            <el-radio :label="2">规自局</el-radio>
                            <el-radio :label="3">鑫诚公司</el-radio>
                            
                        </div>
                        <div style="margin-top:20px;">
                            <el-radio :label="4">交警中队</el-radio>
                            <el-radio :label="5">交警大队</el-radio>
                            <el-radio :label="6">分管领导</el-radio>
                            <el-radio :label="7">局长</el-radio>
                        </div>
                        
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="userForm.user_phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="userForm.user_mail"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch style="display: block;margin-top: 8px;" v-model="userForm.user_state" active-color="#13ce66" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userDialog = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {post,get} from '../../util/util'

export default{
    data(){
        return{
            searchForm:{
                create_time:[],
                user_state:0,
                gp_id:0,
                user_login_id:''
            },

            userDialog:false,
            title:'',
            userForm:{
                user_id:0,
                user_name:'',
                user_login_id:'',
                user_phone:'',
                user_mail:'',
                user_state:1,
                user_type:0,
                user_group_id:0
            },
            disabled:false,
            rules: {
                user_name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                user_login_id: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
            },
        }
    },
    created(){
        if(this.$store.state.GroupDataList.length===0){
            this.$store.dispatch('getGroupDataList')
        }
    },
    mounted(){
        if(this.$store.state.UserDataList.length===0){
            this.$store.dispatch('getUserDataList')
        }
    },
    methods:{
        Add(){
            this.userDialog=true
            this.title='新增'
            this.userForm.user_id=0
            this.userForm.user_name=''
            this.userForm.user_login_id=''
            this.userForm.user_phone=''
            this.userForm.user_mail=''
            this.userForm.user_state=1
            this.userForm.user_group_id=0
            this.userForm.user_type=0
            this.disabled=false
        },
        Search(){
            this.searchForm.begintime=''
            this.searchForm.endtime=''
            if(this.searchForm.create_time&&this.searchForm.create_time.length>0){
                this.searchForm.begintime=this.searchForm.create_time[0]
                this.searchForm.endtime=this.searchForm.create_time[1]
            }
            this.$store.dispatch('getUserDataList',this.searchForm)
        },
        Edit(row){
            this.userDialog=true
            this.title='修改'
            this.userForm=JSON.parse(JSON.stringify(row))
            this.disabled=true
        },
        Del(row){
            this.$confirm('确定删除管理员“' + row.user_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                get('user/del?id=' + row.user_id).then(res => {
                    if (res.rpStatus === 10000) { this.$store.dispatch('getUserDataList') } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            this.$refs.userForm.validate((valid) => {
                if (valid) {
                    post('user/edit', this.userForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.$store.dispatch('getUserDataList')
                            this.userDialog = false
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        }
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search{
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.item label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>